.about_contact {

    .content {
        .map {
            width: 100%;
            height: 720px;
            background-color: rgb(196, 196, 196);
            position: relative;
            
            
            #mapCurrent{
                width: 100%;
                height: 100%;
            }
            .map-container{
                width: 100%;
                height: 100%;
            }
            .link {
                position: absolute;
                z-index: 99;
                display: flex;
                gap: 32px;
                justify-content: center;
                color: #fff;
                font-size: 32px;
                text-shadow: 1px 1px 1px #888888;
                font-weight: 700;
                line-height: 32px;
                max-width: 100vw;
                letter-spacing: 0px;
                text-align: center;
                left: 50%;
                top: 40%;
                transform: translate(-50%, -50%);
                @media screen and (max-width:750px) {
                    flex-wrap:wrap;

                }
                div {
                    margin: 0;
                    cursor: pointer;
                }
                .act{
                    color: rgb(0, 61, 166);
                }
            }

            .content_info {
                position: absolute;
                z-index: 999;
                max-width: 900px;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                height: 250px;
                background: rgb(236, 241, 249, 0.9);
                padding: 40px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .title {
                    color: rgb(0, 0, 0);
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0px;
                    margin-bottom: 15px;
                }

                .address,
                .phone,
                .email {
                    display: flex;
                    color: rgb(142, 142, 142);
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0px;
                    align-content: flex-start;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 16px;
                    .text{
                        width: 90%;
                    }
                }

                .svg_address {
                    width: 20px;
                    margin-top: 2px;
                    height: 20px;
                    background-image: url('../../static/svg/map_point.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }

                .svg_phone {
                    width: 20px;
                    margin-top: 3px;
                    height: 20px;
                    background-image: url('../../static/svg/phone.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }

                .svg_email {
                    width: 20px;
                    margin-top: 4px;
                    height: 20px;
                    background-image: url('../../static/svg/email.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }
            }
        }

        .infomation {
            display: flex;
            flex-direction: column;
            align-items: center;
            @media (max-width:900px){
                width: 85%;
                margin: 0 auto;
            }

            .form {
                width: 100%;
                max-width: 900px;
            }

            .content_title {
                margin-top: 87px;
                margin-bottom: 32px;
                max-width: 900px;
                width: 100%;
                color: rgb(0, 0, 0);
                font-size: 32px;
                font-weight: 700;
                line-height: 56px;
                letter-spacing: 0px;
            }

            .person_info {
                max-width: 900px;
                width: 100%;
                border-top: 1px solid rgb(197, 208, 229, 0.6);

            }
        }
    }

    .ant-input {
        border-radius: 0;
        padding: 20px;
    }

    .ant-input::-webkit-input-placeholder {
        color: rgb(0, 0, 0);
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
    }

    .ant-input:-moz-placeholder {
        color: rgb(0, 0, 0);
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
    }

    .ant-input:-ms-input-placeholder {
        color: rgb(0, 0, 0);
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
    }
    .ant-btn{
        padding: 20px 78px;
        height: 64px;
        border-radius: 0;
        background-color: rgb(160, 182, 222);
    }
}
.gm-style-mot{
    top: 15%;
}
.endtext{
    margin-left: 32px;
    color: #888;
}
