.products3 {
    width: 100%;
    margin-top: 73px;
    .hidden {
        display: none;
      }
    table{
        width: auto !important;
        min-width: 1200px !important;
        table-layout: 100%;  
        th:last-child{
            text-align: center;
        }
    }
    @media(max-width:640px){
        margin-top: 60px;
    }

    .download {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        cursor: pointer;

        a {
            color: #000;
        }

        &:hover a {
            color: rgb(0, 61, 166);
        }
    }

    .download_none {
        display: none;
        opacity: 0;
    }
    @keyframes changeleft {

        0% {
            -webkit-transform: rotate(0deg);
        }

        50% {
            -webkit-transform: rotate(180deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }

    }
    .svg_load {
        background-image: url('../../static/svg/load.svg');
        width: 20px;
        height: 20px;
        background-size: cover;
        margin: 0 auto;
        -webkit-animation:changeleft 1s linear infinite;
        

        
    }

    .top_bg {
        height: 480px;
        width: 100%;
        position: relative;

        .bg {
            img {
                height: 480px;
                width: 100%;
                object-fit: contain;

            }
        }

        .select {
            position: absolute;
            bottom: 10px;
            right: 10px;
            display: flex;
            z-index: 2;

            @media screen and (max-width: 640px) {
                margin: 0 auto;
                position: relative;
                bottom: 0;
                background-color: #eee;
                right: 0;
            }

            img {
                width: 105.26px;
                height: 63.99px;
                object-fit: contain;
                // opacity: 0.5;
            }

            .item {
                width: 105.26px;
                height: 63.99px;
                opacity: 0.5;
                background-color: rgb(255, 255, 255)
            }

            .activtyitem {
                width: 105.26px;
                height: 63.99px;
                position: relative;
                opacity: 1;

                &::after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: rgb(184, 210, 255, 0.4);
                }

            }

            // &::after {
            //     content: '';
            //     width: 100%;
            //     height: 100%;
            //     position: absolute;
            //     background-color: rgb(184, 210, 255, 0.2);
            //     top: 0;
            // }
        }

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: rgb(184, 210, 255, 0.1);
            top: 0;

        }
    }

    .content {
        margin-top: 60px;

        .leftborder {
            border-left: 1px solid #dedede;
            margin-bottom: 32px;
            padding-left: 32px;

            @media (min-width: 900px) {
                max-width: 400px
            }
        }

        .ant-row {
            max-width: 1200px;
            position: relative;
            margin: 0 auto;
            justify-content: space-between;

            @media (max-width: 900px) {
                flex-direction: column;
                padding: 32px;
            }

            .ant-col {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                @media (min-width: 900px) {
                    flex: 1;
                    max-width: 45%;
                }

                @media (min-width: 1200px) {
                    flex: 1;
                    max-width: 45%;
                }

            }

        }

        .left {
            max-width: 450px;
            margin-bottom: 20px;

            @media (max-width: 1200px) {
                padding-left: 28px;
            }

            @media (max-width: 900px) {
                padding-left: 0;
            }

            .title {
                font-weight: 700;
                font-size: 40px;
                margin-bottom: 27px;
            }

            .item_content {
                font-size: 14px;
                color: rgb(85, 85, 85, 0.8);
            }
        }

        .content_center {
            margin: 0 auto;

            width: 1px;
            height: 349px;
            background-color: rgb(184, 210, 255, 0.2);
        }

        .right {
            max-width: 450px;

            .title {
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 18px;
            }

            .item_content {
                font-size: 14px;
                color: rgb(85, 85, 85, 0.8);
            }
        }

        .table {
            width: 100%;
            padding-top: 32px;
            background-color: rgb(196, 196, 196, 0.1);
            transition: all 0.5s;

            .title {
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 20px;
            }

            .table_content {
                margin: 0 auto;
                max-width: 1200px;

                @media (max-width: 1200px) {
                    padding-left: 28px;
                }

                .ant-pagination {

                    .ant-pagination-next,
                    .ant-pagination-prev,
                    .ant-select {
                        display: none;
                    }

                    .ant-pagination-item-active {
                        border-color: transparent;
                        background-color: transparent;

                        a {
                            color: #1677ff !important;
                        }
                    }

                    .ant-pagination-item a {
                        color: #00000076;
                    }
                }

                .ant-table {
                    background-color: rgb(248, 248, 248);

                    .ant-table-thead {
                        .ant-table-cell {
                            background-color: rgb(248, 248, 248);
                            padding: 5px;
                            border-bottom: 1px solid #000;
                            padding-bottom: 16px;
                            font-size: 14px;
                            font-weight: 500;
                            overflow-wrap:normal;
                            min-width: 80px;                           
                            &::before {
                                background-color: rgb(248, 248, 248) !important;
                            }
                        }


                    }
                }

                .ant-table-tbody {
                    .ant-table-cell {
                        background-color: rgb(248, 248, 248);
                        padding: 14px 5px;

                    }
                }
            }
        }
    }
}

.products3 {
    :where(.css-dev-only-do-not-override-ed5zg0).ant-row-center {
        justify-content: space-between;
    }
}